import React, { TextareaHTMLAttributes } from 'react'
import { cn } from 'shared/utils/web/cn'

export interface ButtonProps
  extends React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    React.AriaAttributes {
  primary?: boolean
  primaryColor?: string
  className?: string
}

export const defaultColor = 'bg-sky-600'
export const defaultPrimaryColor = 'bg-green-600'

export const Button: React.FC<ButtonProps> = ({
  children,
  primary,
  className,
  primaryColor,
  ...props
}) => (
  <button
    {...props}
    className={cn(
      'rounded-md px-4 py-2 font-semibold text-white shadow-md disabled:cursor-not-allowed disabled:opacity-30',
      primary
        ? primaryColor
          ? primaryColor
          : defaultPrimaryColor
        : defaultColor,
      'hover:bg-opacity-50',
      className,
    )}
  >
    {children}
  </button>
)

type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement>

export const TextArea = ({ children, ...props }: TextAreaProps) => (
  <textarea
    className={`rounded-md bg-white px-2 py-1 text-black focus:outline-none disabled:opacity-50`}
    {...props}
  >
    {children}
  </textarea>
)
