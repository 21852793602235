import { LABEL_TEXT, LabelKey, labelsPaginationMap } from 'common/ontology'
import { addOggExtension } from 'common/soundKey'
import {
  HardLabel,
  LabelProposal,
  LabelsDbGetLabeledSoundResponse,
  SubLabelKey,
} from 'common/types'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useEffect, useMemo, useState } from 'react'
import { DEFAULT_REGION } from 'shared/firebase/region'
import { isObjectEmpty } from 'shared/utils/defined'
import { formatIsoDate, formatIsoTime } from 'shared/utils/time'
import { siteUrl } from 'shared/utils/url'
import { onError } from 'shared/utils/web/error'
import { HardLabelsPreview } from './HardLabelsPreview'
import { labelingConfigMap } from './Labeling'
import { Player } from './Player'
import {
  GetLabelsDbSoundParams,
  GetLabelsDbSoundResponse,
} from './common/types'
import { Button } from './components'
import { app, auth } from './firebase'
import { get } from './firebaseMethods'
import { getFilePathTime } from './utils'

const functions = getFunctions(app, DEFAULT_REGION)
const getLabelsDbSound = httpsCallable<
  GetLabelsDbSoundParams,
  GetLabelsDbSoundResponse
>(functions, 'getLabelsDbSound')

interface Props {
  getUrl: (path: string) => Promise<string>
}

function listenUrl(path: string) {
  return `${siteUrl('listen')}/${path}`
}

function getParticleFromUrl() {
  const particle = window.location.href.split('=')[1]
  if (particle.split('/').length !== 3) return null
  return particle
}

export const Review = ({ getUrl }: Props) => {
  const { playbackRates } = labelingConfigMap[import.meta.env.VITE_ONTOLOGY]
  const labelsPagination = labelsPaginationMap[import.meta.env.VITE_ONTOLOGY]

  // undefined = no yet set
  // null = no labeled sound found in LabelsDB
  const [labelsDbSound, setLabelsDbSound] = useState<
    LabelsDbGetLabeledSoundResponse | null | undefined
  >(undefined)

  // undefined = no yet set
  // null = no valid data found in URL
  const [particle, setParticle] = useState<string | null | undefined>(undefined)
  const [soundUrl, setSoundUrl] = useState<string | undefined>(undefined)
  const [userRole, setUserRole] = useState<string | undefined>(undefined)

  useEffect(() => {
    setParticle(getParticleFromUrl())
  }, [])

  useEffect(() => {
    async function getUserRole() {
      const user = await get(`users/${auth.currentUser?.uid}`)
      setUserRole(user.role)
    }
    getUserRole()
  }, [])

  useEffect(() => {
    async function fetchLabelsDbSound() {
      if (particle) {
        const ontology_name =
          import.meta.env.VITE_ONTOLOGY === 'layer1'
            ? 'big-ontology'
            : import.meta.env.VITE_ONTOLOGY
        await getLabelsDbSound({ particle })
          .then((result) => {
            const data = result.data
            setLabelsDbSound(
              data !== null
                ? {
                    ...data,
                    labeling: data.labeling.filter(
                      (labeling) => labeling.ontology_name === ontology_name,
                    ),
                  }
                : null,
            )
          })
          .catch(onError)
      }
    }
    fetchLabelsDbSound()
  }, [particle])

  useEffect(() => {
    async function fetchSoundUrl() {
      if (particle) {
        const soundUrl = await getUrl(addOggExtension(particle))
        setSoundUrl(soundUrl)
      }
    }

    fetchSoundUrl()
  }, [particle, getUrl])

  const softLabelsMatrix = useMemo(() => {
    let atLeastOneVote: (LabelKey | SubLabelKey | LabelProposal)[] = []
    if (particle) {
      if (labelsDbSound) {
        for (const labeling of labelsDbSound.labeling) {
          for (const label of labeling.labels ?? []) {
            if (
              !('start' in label) &&
              !('end' in label) &&
              !atLeastOneVote.includes(label.label)
            ) {
              atLeastOneVote.push(label.label)
            }
          }
        }
      }
    }
    atLeastOneVote = atLeastOneVote.sort((a, b) => a.localeCompare(b))

    const matrix: Record<string, (string | null)[]> = {}
    if (particle) {
      if (labelsDbSound) {
        for (const labeling of labelsDbSound.labeling) {
          const labels = (labeling.labels ?? []).map((label) => label.label)
          matrix[labeling.user_id] = atLeastOneVote.map((label) =>
            labels.includes(label) ? label : null,
          )
        }
      }
    }

    return matrix
  }, [particle, labelsDbSound])

  const hardLabelsMatrix = useMemo(() => {
    const matrix: Record<string, HardLabel[]> = {}
    if (particle) {
      if (labelsDbSound) {
        for (const labeling of labelsDbSound.labeling) {
          const hardLabels: HardLabel[] = []
          for (const label of labeling.labels ?? []) {
            if ('start' in label && 'end' in label) hardLabels.push(label)
          }
          matrix[labeling.user_id] = hardLabels
        }
      }
    }

    return matrix
  }, [particle, labelsDbSound])

  async function copyListenLinkToClipboard() {
    const link = listenUrl(particle ?? 'error')
    await navigator.clipboard.writeText(link)
    alert(
      `Le lien vers listen a été copié dans le presse papier (Ctrl + V pour l'utiliser).`,
    )
  }

  if (particle === undefined || labelsDbSound === undefined) {
    return <h2>Chargement...</h2>
  }

  if (userRole !== 'OSO') {
    return <h2>Accès refusé</h2>
  }

  if (particle === null) {
    return <h2>URL invalide</h2>
  }

  const labelTime = getFilePathTime(particle)

  return (
    <div className="flex min-h-screen w-screen flex-col">
      {soundUrl && (
        <Player
          labelTime={labelTime}
          src={soundUrl}
          autoplay={false}
          mutedRanges={[]}
          playbackRates={playbackRates}
        >
          {(audioElementRef) =>
            Object.values(hardLabelsMatrix).some(
              (hardLabels) => hardLabels.length !== 0,
            ) && (
              <div className="divide-gr absolute bottom-0 left-0 right-0 top-0 z-10 flex flex-col divide-y divide-dashed">
                {Object.entries(hardLabelsMatrix).map(
                  ([uid, hardLabels]) =>
                    audioElementRef.current && (
                      <HardLabelsPreview
                        key={uid}
                        duration={audioElementRef.current.duration}
                        hardLabels={hardLabels}
                        labelsPagination={labelsPagination}
                      />
                    ),
                )}
              </div>
            )
          }
        </Player>
      )}
      <div className="flex flex-auto flex-col justify-evenly px-4 text-center text-lg font-semibold">
        {isObjectEmpty(labelsDbSound ?? {}) && (
          <div className="p-2">Aucune labellisation pour ce son</div>
        )}
        {labelsDbSound &&
          labelsDbSound.labeling.map((labeling) => {
            const uid = labeling.user_id
            return (
              <div key={uid}>
                <div className="my-4 flex flex-row justify-evenly rounded-md bg-gray-800">
                  <div className="p-2">
                    {`${uid} - ${labeling.duration.toFixed()} s`}
                  </div>
                  <div className="p-2">
                    {`Date de labellisation : ${formatIsoDate(labeling.ts)} ${formatIsoTime(labeling.ts)}`}
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-2">
                  {softLabelsMatrix[uid].map((label, index) => (
                    <div
                      key={`${uid}_${index}`}
                      className={`${
                        label !== null
                          ? !label.startsWith('#')
                            ? 'bg-sky-500'
                            : 'bg-yellow-500'
                          : 'bg-gray-500/50'
                      } rounded-md text-center font-semibold`}
                    >
                      {label !== null
                        ? !label.startsWith('#')
                          ? LABEL_TEXT[
                              label.split('.').slice(-1)[0] as LabelKey
                            ]
                          : label
                        : ''}
                    </div>
                  ))}
                </div>
              </div>
            )
          })}
      </div>
      <div className="flex flex-row flex-wrap gap-4 p-4">
        <Button
          title="Copier le lien listen"
          disabled={particle === null}
          onClick={copyListenLinkToClipboard}
        >
          <span role="img" aria-label="Partager">
            📋
          </span>
        </Button>
        <a
          href={`${listenUrl(particle)}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button title="Se rendre sur listen" disabled={particle === null}>
            <span role="img" aria-label="Partager">
              👂
            </span>
          </Button>
        </a>
        <a href={soundUrl} target="_blank" rel="noopener noreferrer">
          <Button title="Télécharger le son" disabled={particle === null}>
            <span role="img" aria-label="Télécharger">
              📥
            </span>
          </Button>
        </a>
      </div>
    </div>
  )
}
